import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import { withTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { getLanguage } from '../utils/utility';
import { SUPPORTED_LANGUAGE } from '../utils/constant';

function NotFoundPage(props) {
  const [cookies] = useCookies(['language_id']);

  let currentLanguage = getLanguage(cookies.language_id);
  if (currentLanguage === null) {
    currentLanguage = SUPPORTED_LANGUAGE[0];
  }
  const dir = currentLanguage && currentLanguage.dir;
  const { t } = props;

  return (
    <section className="layout default-layout">
      <Helmet htmlAttributes={{ lang: currentLanguage?.code, dir: dir }}>
        <title>Not Found</title>
      </Helmet>
      <div className="main-card">
        <div className="section not-found-error-page">
          <img alt="" src="/assets/icon/not-found.svg" />
          <h1>{t("We're sorry!")}</h1>
          <p>{t('The menu you are looking for could not be found.')}</p>
          <a href="https://getmenu.ps">{t('Search For Your Menu')}</a>
        </div>
        <Footer />
      </div>
    </section>
  );
}

export default withTranslation()(NotFoundPage);
