import React from 'react';
import useMenu from '../../../hooks/useMenu';
import { Product } from './Product';
import FishiesGroup from './FishiesGroup';

const PRICE_GROUP_LIST = ['MOCHI', 'TAIYAKI', 'TAIYAKI ICE CREAM', 'POWER MOCKTAILS', 'SAN SABSTIAN'];

const Group = ({ group }) => {
  const { getProductsInfoByIds } = useMenu();

  const products = getProductsInfoByIds(group.items);

  const containerStyles = () => {
    if (['MOCHI', 'TAIYAKI', 'TAIYAKI ICE CREAM'].includes(group.name)) {
      return {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        fontSize: 10,
        paddingBottom: 12,
      };
    }
    return {};
  };

  if (group.identifier === 'FISHIES') {
    return <FishiesGroup group={group} />;
  }

  return (
    <div group-id={group.id} style={{ marginBottom: 30 }}>
      <div style={{ textAlign: 'center' }}>
        <img
          src={group.thumbnail}
          alt={group.name}
          style={{ width: '100%', border: '1px solid lightgray', borderRadius: 10 }}
        />
        <div style={{ fontSize: 18, fontWeight: 600, marginTop: 15 }}>{group.name}</div>
      </div>

      {PRICE_GROUP_LIST.indexOf(group.name) !== -1 && (
        <div style={{ fontWeight: 600, padding: 20, fontSize: 20, display: 'flex', justifyContent: 'center' }}>
          {products[0]?.price} / Piece
        </div>
      )}

      <div style={{ ...containerStyles() }}>
        {products.map((product, index) => (
          <Product key={index} product={product} index={index} groupName={group.name} />
        ))}
      </div>
    </div>
  );
};

export default Group;
