import React from 'react';
import useMenu from '../../hooks/useMenu';
import useProduct from '../../hooks/useProduct';
import ProductActions from '../ProductActions';
import ProductPrice from '../ProductPrice';
import ProductProperties from '../ProductProperties';
import ProductRate from '../ProductRate';
import ProductThumbnail from '../ProductThumbnail';
import ProductDescription from './ProductDescription';
import ProductName from './ProductName';

function ProductCard({ productId }) {
  const { rateEnabled } = useMenu();

  const { description, name, rate, total_view, properties, partnumber, thumbnail } = useProduct(productId);

  return (
    <div className="product-card" product-id={productId} productss-partnumber={partnumber}>
      <ProductThumbnail url={thumbnail} productId={productId} />

      <div className="product-details">
        {name && <ProductName name={name} productId={productId} />}

        {description && <ProductDescription description={description} />}

        <ProductPrice productId={productId} />

        {rateEnabled && <ProductRate rate={rate} totalView={total_view} />}

        {properties && <ProductProperties properties={properties} />}

        <ProductActions productId={productId} />
      </div>
    </div>
  );
}

export default ProductCard;
