import React from 'react';
import { MEDIA_BASE_URL } from '../../utils/constant';

function Icon(props) {
  let path = props.src;
  if (path) {
    if (!path.startsWith('http')) {
      path = MEDIA_BASE_URL + path;
    }

    if (props.active) {
      path = path.replace(/(\.png|\.jpg|\.jpeg|\.gif)$/, '_active$1');
    }
  }
  return (
    <div
      className={'category ' + (props.active ? 'active' : '')}
      onClick={props.onClick}
      category-id={props.categoryId}
    >
      <img alt="" src={path} />
    </div>
  );
}

export default Icon;
