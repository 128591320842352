import React from 'react';
import FormatPrice from '../../../components/FormatPrice';
import useLocalization from '../../../hooks/useLocalization';
import useProduct from '../../../hooks/useProduct';

const FishiesGroup = ({ group }) => {
  const fishiesProduct = useProduct(group.items[0]);
  const { currentCurrency } = useLocalization();

  const RenderMerchandisingAssociations = ({ id, partNumber, thumbnail, name, price }) => {
    return (
      <div
        product-id={id}
        part-number={partNumber}
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <p style={{ margin: '2px', display: 'flex', alignItems: 'center', gap: 5 }}>
          <img src={thumbnail} width="25" />
          {name}
        </p>
        <p style={{ margin: '0', color: '#ea2628' }}>
          <FormatPrice value={price} />
        </p>
      </div>
    );
  };

  return (
    <div style={{ padding: '15px' }}>
      <img
        src="https://images.getmenu.ps/accounts/6/21/category/FISHIES.jpg"
        alt="Fishies"
        style={{ display: 'block', width: '100%', borderRadius: '15px' }}
      />
      <p
        style={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 'bold',
          color: '#5777ba',
        }}
      >
        {group.name}
      </p>
      <p
        style={{
          fontSize: '22px',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {fishiesProduct.name}:{' '}
        <span style={{ color: '#ea2628' }}>{fishiesProduct['price_' + currentCurrency.id]}₪</span>
      </p>

      <div
        style={{
          fontWeight: 'bold',
          fontSize: '18px',
          padding: '10px',
          border: '1px solid #000',
          position: 'relative',
        }}
      >
        <span
          style={{
            position: 'absolute',
            top: '-13px',
            background: 'white',
            left: 'calc(50% - 33px)',
            padding: '0 10px',
          }}
        >
          SAUCES
        </span>

        <div>
          {fishiesProduct?.merchandisingAssociations()?.map((item) => (
            <RenderMerchandisingAssociations
              id={item.id}
              partNumber={item.partnumber}
              thumbnail={item.thumbnail ? item.thumbnail : item.media?.[0]?.path}
              price={item['price_' + currentCurrency.id]}
              name={item.name}
            />
          ))}
        </div>
        <p style={{ textAlign: 'center', marginBottom: '0' }}>يقدم 1 صوص مع كل وجبة</p>
      </div>
    </div>
  );
};

export default FishiesGroup;
